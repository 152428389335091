@import url('https://fonts.googleapis.com/css?family=Open+Sans|Teko');
$SANS_SERIF_FONT_STACK: "Teko", Verdana, Arial, Helvetica, sans-serif;
$SERIF_FONT_STACK: 'Open Sans', 'Times New Roman', serif;

$BODY_FONT_STACK: $SERIF_FONT_STACK;
$HEADING_FONT_STACK: $SANS_SERIF_FONT_STACK;

@mixin heading-font {
	font-family: $HEADING_FONT_STACK;
	font-weight: 600;
	-webkit-font-smoothing: antialiased;
}

// A simple rem font size mixin assume 62.5% for html element
@mixin font-size($sizeValue: 1.6) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}
