/*** Content div for Post & Page text content */

.sidebar {
	.callout {
		margin-left: auto;
		margin-right: auto;
		max-width: 500px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		margin-bottom: 20px;
		color: $WHITE;
		background-size: cover;
		background-repeat: no-repeat;
		width: 75%;
		text-align: center;

		h2, h3, a {
			color: $WHITE;
			margin: 0;
		}

		a {
			text-decoration: none;
			text-transform: uppercase;
			width: 100%;
			height: 100%;
			display: block;
			padding: 80px 5px;
		}
	}
}

.sub-page-title {
	font-weight: bold;
	@include font-size(2.4);
	margin-bottom: 30px;

	@include respond-to("max-width: " + $VERY_SMALL_SCREEN_WIDTH) {
		@include font-size(2.0);
		margin-bottom: 20px;
	}
}

.textblock {
	margin-bottom: 30px;

	.title {
		@extend .sub-page-title;
	}
}

.content {
	@include clearfix();
	@include font-size(1.6);

	a {
		font-weight: 700;
		text-decoration: none;
	}

	li {
		line-height: 1.25;
		margin-bottom: .5em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul li {
		list-style-type: none;
		position: relative;

		&:before {
			position: absolute;
			top: 0;
			left: -1.5em;
			content: '●';
			font-size: 75%;
			padding-top: 3px;
			color: $MEDIUM_GREY;
		}
	}

	h2, h3, h4, h5 {
	}

	h2 {
		@include font-size(3.6);
		font-weight: normal;
	}

	h3 {
		@include font-size(2.7);
		font-weight: 600;
	}

	h4 {
		@include font-size(2.4);
		font-weight: 700;
	}

	strong {
		font-weight: 700;
	}

	blockquote {
		font-style: normal;
		@include font-size(1.8);
		line-height: 1.5;
	}

	dl {
		margin-bottom: 20px;

		dt {
			@include font-size(1.6);
			margin-bottom: 5px;
		}

		dd {
			margin: 0;
			padding: 0;
			line-height: 1.5;
			margin-bottom: 25px;
		}
	}

	figure, img {
		max-width: 100%;
		height: auto;
		display: block;
		margin-bottom: 1.5em;

		&.alignleft, &.left {
			float: left;
			max-width: 50%;
			margin-right: 1.5em;
		}

		&.alignright, &.right {
			float: right;
			max-width: 50%;
			margin-left: 1.5em;
		}

		&.center {
			margin-left: auto;
			margin-right: auto;
			max-width: 75%;
		}
	}

	.button {
		max-width: 300px;
	}

	.image-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		img {
			margin-right: 10px;
		}
	}

	@include respond-to("max-width: " + $SMALL_SCREEN_WIDTH) {
		figure, img {
			&.alignleft, &.alignright, &.left, &.right, &.center {
				float: none;
				width: auto;
				max-width: 100%;
				padding-right: 0;
				margin-left: 0;
			}
		}

		.image-grid {
			flex-direction: column;
		}
	}

	@include respond-to("max-width: " + $VERY_SMALL_SCREEN_WIDTH) {
		h2 {
			@include font-size(2.5);
		}

		h3 {
			@include font-size(2.25);
		}

		h4 {
			@include font-size(2.0);
		}
	}
}
