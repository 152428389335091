$WRAPPER_PADDING: 6%;

%wrapper {
	padding: 0 $WRAPPER_PADDING;
	@include clearfix();
	position: relative;
}

%sidebar {
	position: relative;
	float: left;
	width: 25%;
	margin-left: 5%;
	min-height: 1px; // prevent horizontal collapse
	background-size: contain;
	background-image: url(/static/images/sidebar-bg-tile.png);
	@include respond-to("max-width:" + $SMALL_SCREEN_WIDTH) {
		width: auto;
		float: none;
		margin-left: 0;
		margin-top: 30px;
		padding-top: 30px;
		border-top: 1px solid $LIGHT_GREY;
	}

}

%main-column {

	width: 70%;
	min-height: 1px; // prevent horizontal collapse
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	background-image: url(/static/images/content-bg.png);
	@include respond-to("max-width:" + $SMALL_SCREEN_WIDTH) {
		width: auto;
		float: none;
	}
}

// 2 column layout class
.two-column-layout {
	@include clearfix();

	.sidebar {
		@extend %sidebar;
	}

	.main-column {
		@extend %main-column;
	}
}

.one-column-narrow-layout .main-column {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}
