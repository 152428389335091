section#Banner {
    @extend .trans;
    background-color: $BLUE;
    padding: 2.5% 0;
	margin: 20px 0;
	text-align: center;
	align-items: center;
	&:hover {
        // background-color: $MEDIUM_GREY;
	}
	h2 {
		@include font-size(8);
		color: $TAN;
		padding: 0 2.5%;
		@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
			margin-bottom: unset;
			padding: 20px;
			@include font-size(5);
		}
	}
	div.wrap {
		display: flex;
		flex-direction: column;
		// filter: drop-shadow(10px 10px 0px $WHITE) drop-shadow(0px -10px 0px $TAN) drop-shadow(-10px 0px 0px $YELLOW);
		div {
			display: flex;
			align-items: center;
			&.content {
				width: 66%;
				flex-direction: column;
				// border: 4px solid $TAN;
				background-color: $RED;
				padding: 5%;
				* {
					color: $WHITE;
				}
				h3, h4 {
					text-transform: uppercase;
				}
				h3 {
					margin-bottom: unset;
					margin: 10px;
					span {
						color: $TAN;
					}
				}
				h4 {
					@include font-size(4);
					margin: unset;
					line-height: .8;
				}
				a:not(.link-button) {
					text-decoration: underline;
				}
				a.link-button {
					@include font-size(3);
				}
	
				@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
					width: 100%;
				}
				@include respond-to("min-width: " + $MEDIUM_SCREEN_WIDTH) {
					padding: 2.5% 10% 2.5% 5%;
					clip-path: polygon(0 0, 100% 0, 92.5% 100%, 0% 100%);
					h3 {
						@include font-size(4.5);
					}
					h4 {
						@include font-size(5);
					}
					a.link-button {
						display: none;
					}
				}
			}
			&.other {
				justify-content: left;
				flex-direction: column;
				padding: 40px;
				div {
					width: auto;
					* {
						color: white;
					}
					a {
						&.link-button {
							@include font-size(4);
						}
					}
					img {
						max-width: 125px;
					}
				}
				@include respond-to("min-width: " + $MEDIUM_SCREEN_WIDTH) {
					display: none;
					width: 33%;
					display: flex;
					justify-content: center;
					padding: 2vw 3vw;
					div {
						a {
							// text-decoration: underline;
							&.link-button {
								@include font-size(7);
								padding: 30px 60px;
							}
						}
					}
				}
			}
		}
		@include respond-to("min-width: " + $MEDIUM_SCREEN_WIDTH) {
			flex-direction: row;
		}
	}
	a {
		width: 100%;
	}
}
section#EventsUpcoming {
	display: flex;
	flex-direction: row;

	time {
		color: $TAN;
		font-family: $SANS_SERIF_FONT_STACK;
		@include font-size(2);
		font-weight: 600;
		text-transform: uppercase;
	}

	.event {
		width: 40%;
		padding: 4%;
	}

	.all-event-link {
		width: 20%;
		padding: 4%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url(/static/images/mp-events-bg-btn.png);
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;

		a {
			display: block;
			text-decoration: none;
			color: $WHITE;
			font-size: 2em;
			font-weight: 600;
			font-family: 'Teko';
			padding: 20px;
		}
	}

	.event {
		background-color: $BLUE;

		time {
			color: $TAN;
		}

		.event-name, .event-description {
			color: $WHITE;
		}
	}

	@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
		flex-direction: column;

		.event, .all-event-link {
			width: 100%;
			padding-bottom: 10%;
		}
	}
}

section#Callouts {
	display: flex;
	flex-direction: row;
	justify-content: center;

	.callout {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		width: 30%;
		min-height: 500px;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		padding: 2vw 3vw;
		margin-top: 20px;
		margin-bottom: 20px;
		color: $WHITE;

		img {
			margin-bottom: 20px;
		}

		h2 {
			color: $WHITE;
			font-size: 35px;
		}

		&#PlanPrivateEvent {
			background-image: url(/static/images/mp-callout_plan.jpg);
		}

		&#Tailgating {
			background-image: url(/static/images/mp-callout_tailgating.jpg);
		}

		&#Sponsor {
			background-image: url(/static/images/mp-callout_sponsor.jpg);
		}
	}

	@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
		flex-direction: column;
		text-align: center;
		align-items: center;

		.callout {
			width: 94%;
			align-items: center;
			justify-content: center;
			min-height: 0;
			padding: 6vw;
			margin-top: 20px;
			margin-bottom: 0;

			.p {
				text-align: center;
			}
		}

		.callout:last-child {
			margin-bottom: 20px;
		}
	}
}

section#HomePagePromos {
	display: flex;
	flex-direction: row;

	.promo {
		width: calc(100% / 3);
		height: 400px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		flex-direction: column;
		padding: 4% 1%;
	}
}

section#AboutTheStadium {
	background-image: url(/static/images/mp-about-bg.jpg);
	background-size: 100% 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding: 10vw 10vw 0 10vw;

	.text-wrapper {
		width: 45%;
		color: $WHITE;
		margin-bottom: 30px;

		p {
			font-size: 1em;
			font-weight: 300;
		}

		@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
			width: 100%;
		}
	}

	h2 {
		color: $WHITE;
		font-size: 4em;
	}

	.container {
		.row {
			height: 25vw;
			display: flex;

			@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
				flex-direction: column;
				margin: 20px auto;
				height: 20vh;
			}
		}

		.row:nth-child(1) {
			.image {
				align-self: flex-end;
			}
		}

		.image {
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			margin: 0;
			display: inline-block;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				text-decoration: none;
				color: $WHITE;
				font-size: 3em;
				font-family: 'Teko';
				font-weight: 600;
			}

			&.large {
				width: calc(100%/3 * 2);
				height: 100%;
			}

			&.small {
				width: calc(100% / 3);
				height: 50%;
			}

			&#Facts {
				background-image: url(/static/images/mp-about-callout-lg_facts.jpg);
			}

			&#History {
				background-image: url(/static/images/mp-about-callout-sm_history.jpg);
			}

			&#Commission {
				background-image: url(/static/images/mp-about-callout-sm_commission.jpg);
			}

			&#Diagram {
				background-image: url(/static/images/mp-about-callout-lg_diagram.jpg);
			}

			@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
				&.large, &.small {
					width: 100% !important;
					height: 50% !important;
					display: block;
					font-size: 0.9em;
					margin: 5px auto;
				}
			}
		}
	}
}

section#NewsAndAnnouncements {
	display: flex;
	padding: 5vw;
	align-items: center;

	.wrapper {
		width: 50%;
		padding: 5vw;

	}

	h2 {
		@include font-size(6);
		margin-bottom: 10px;
	}

	.news-item {
		background-color: white;
		margin: 20px;
		padding: 3vw;
		border-right: 10px solid $TAN;
		box-shadow: 5px 5px 20px rgba(10, 10, 10, .2);

		time {
			color: $TAN;
			font-family: $SANS_SERIF_FONT_STACK;
			@include font-size(2);
			font-weight: 600;
			text-transform: uppercase;
		}

		a {
			text-decoration: none;

			h2 {
				@include font-size(3);
			}
		}

		a.red {
			color: $RED;
			font-family: $SANS_SERIF_FONT_STACK;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
	@include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
		flex-direction: column;

		.wrapper{
			width: 100%;
			padding: 0;
			margin: 20px auto;
		}

		.wrapper:nth-child(1){
			padding: 3vw;
		}

		h2{
			@include font-size(3);
		}
		padding-bottom: 50px;
	}
}
