@import "../modules/my_compass";
@mixin scalable-font-size($min_size: 2em, $scale_factor: 1vw) {
	font-size: $min_size;
	font-size: calc(#{$min_size} + #{$scale_factor});
}

@mixin block-center  {
	margin: {
		left: auto;
		right: auto;
	}
}

@mixin button($background-color, $color) {
	background-color: $background-color;
	display: inline-block;
	border: 0;
	@include font-size(1.4);
	line-height: 1;
	font-weight: normal;
	padding: 12px 16px;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	text-transform: uppercase;
	color: $color;
	@include heading-font();
	border-radius: $border-radius;

	i {
		margin-left: 10px;
	}

	transition: background-color ease-in 0.2s;

	&:hover {
		background-color: darken($background-color, 10%);
		color: $color;
	}
}

// Horizontal Nav
%horizontal-nav {
	@include inline-block-list();

	li:last-child {
		margin-right: 0;
	}

	a {
		display: block;
	}
}

// Media object from OOCSS
%media {
	@include clearfix;

	.media-img {
		display: block;
		float: left;
		margin-right: 5%;
		width: 30%;

		> img {
			display: block;
		}
	}

	.media-body {
		@include clearfix;
	}
}


// For Article Types (blog posts, events )
%article-list-item {
	padding-bottom: 40px;
	margin-bottom: 40px;

	header {
		margin-bottom: 20px;

		.title {
			@include heading-font();
			@include font-size(2.7);
			margin-bottom: 10px;

			a {

			}
		}

		time {
			@include font-size(1.3);
			margin-bottom: 0;
			display: block;
			margin-bottom: 5px;
			text-transform: uppercase;
		}
	}

	.excerpt {
		margin-bottom: 20px;
		color: lighten($TXT_COLOR, 10%);
	}

	.more {
		max-width: 150px;
		@include font-size(1.4);
		font-weight: bold;
	}

	&:last-child {
		margin-bottom: 0;
	}
	@include respond-to("max-width:" + $SMALL_SCREEN_WIDTH) {
		padding-bottom: 20px;
		margin-bottom: 20px;

		header {
			.title {
				@include font-size(1.8);
			}
		}
	}

}

%article {
	header {
		padding-bottom: 10px;
		margin-bottom: 10px;

		> .title {
			@include font-size(2.5);
			@include heading-font();
		}

		> time {
			@include font-size(1.6);
			display: block;
			text-transform: uppercase;
		}
	}
}

// Video Thumbnail
%video-thumbnail {
	display: block;
	position: relative;

	img {
		display: block;
		width: 100%;
	}

	&:after {
		content: '';
		background: transparent url(../images/play-icon.png) no-repeat 0 0;
		// Adjust based on size of background image icon
		width: 80px;
		height: 80px;
		margin-top: -40px;
		margin-left: -40px;
		// ***************

		top: 50%;
		left: 50%;
		position: absolute;
		z-index: 100;
		transition: transform ease-in 0.3s,
		opacity ease-in 0.5s;
	}

	&:hover {
		&:after {
			opacity: 0.8;
			transform: scale(0.8);
		}
	}
}
