.event-list {
	display: flex;
	justify-content: space-between;
}

.calendar-wrapper {
	margin-top: -40px;
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	align-items: center;


	#Calendar {
		max-width: 500px;
		width: 85%;

		.ui-datepicker-inline {
			background-color: $WHITE;
			padding: 20px 5px;
		}

		.ui-datepicker-header {
			background-color: $WHITE;
			color: $TAN;
			@include font-size(3);
			text-align: center;
			border: none;
			padding: 20px 10px;
			font-family: $SANS_SERIF_FONT_STACK;
			font-weight: 700;
			a{
				text-decoration: none;
				font-family: $SERIF_FONT_STACK;

			}
		}

		table {
			border: none;
		}

		td {
			padding: 5px 2px;
			text-align: center;
			background-color: $WHITE;

			a {
				text-decoration: none;
				font-family: $SERIF_FONT_STACK;
				@include font-size(1.5);
			}
		}

		thead th {
			background: $WHITE;
			border-bottom: none;
			margin-top: 0;
			font-family: $SANS_SERIF_FONT_STACK;
			font-weight: 700;
			padding: 0;
			padding-bottom: 10px;

			span {
				font-family: $SANS_SERIF_FONT_STACK;
				font-weight: 700;
			}
		}

		.ui-datepicker-next, .ui-datepicker-prev {
					color: $TAN;
					&.ui-state-disabled{
						color: $GREY;
					}
		}
		.ui-datepicker-prev{
			float: left;

		}
		.ui-datepicker-next{
			float: right;

		}

		.event-day {
			a {
				color: $RED;
				font-weight: 700;
				@include font-size(2);
			}
		}
	}
}

.event-date-list-wrapper {
	margin: 20px auto;
}

.event {
	width: 48%;

	.title {
		@include font-size(3);

		a {
			@include font-size(3);
		}
	}

	.more {
		color: $RED;
		font-weight: 700;
	}

	&.featured {
		width: 100%;
	}

	time, time > span {

		font-family: $SANS_SERIF_FONT_STACK;
		font-weight: 300;
		text-transform: uppercase;
		color: $BLUE;

	}

	.event-thumbnail {
		width: 100%;
	}

	@include respond-to("max-width: " + $MEDIUM_LARGE_SCREEN_WIDTH) {
		width: 100%;
	}
}
