$YELLOW: #b3aa19;
$BLACK: #222;
$PURPLE: #8e44ad;
$ORANGE: #e67e22;
$BLUE: #012853;
$LIGHT_BLUE: #0E4888;
$DARK_BLUE: #031D39;
$RED: #9F2630;
$LIGHT_RED: #CD333F;
$DARK_RED: #801A23;
$TAN: #B59F67;

$WHITE: #ecf0f1;
// Greys
$LIGHT_GREY: #F2F3F5;
$MEDIUM_GREY: #777;
$DARK_GREY: #444;
$GREY: #A6B0BB;

// Theme Colors
$LINK_COLOR: $BLUE;
$TXT_COLOR: $DARK_BLUE;
$PRIMARY_BUTTON_BACKGROUND_COLOR: $RED;
$PRIMARY_BUTTON_TXT_COLOR: $WHITE;

$PRIMARY_HEADING_COLOR: $DARK_BLUE;
$SECONDARY_HEADING_COLOR: $BLUE;

$ERROR_COLOR: #DF2133;
