@import "partials/base";

.hidden {
    display: none;
}
.tingle-modal-box__content{
    @include respond-to("max-width: " + '594px') {
        overflow-x: hidden!important;
        overflow-y: hidden!important;
    }

}
.modal-dialog {
    .bg-container {
        width: 100%;
        text-align: right;
    }
    .tingle-modal-box{
        width: 54%;
        @include respond-to("max-width: " + '594px') {
            width: 100%;
        }
    }
    li{
        color: $MEDIUM_GREY;
    }

    a{
        text-decoration: underline;
    }
    h1 {
        text-transform: uppercase;
        color: $WHITE;
        display: block;
        position: relative;
        background-color: $RED;
        text-align: center; // text-align: right;
        font-size: 3em;
        -webkit-font-smoothing: antialiased;
        height: 92px;
        padding: 2rem;
        border-bottom: 8px solid $MEDIUM_GREY;
        // margin-left: 20px;
        @include respond-to("max-width: " + $LARGE_SCREEN_WIDTH) {
            font-size: 2.5em;
        }
        @include respond-to("max-width: " + $MEDIUM_LARGE_SCREEN_WIDTH) {
            font-size: 2em;
        }
        @include respond-to("max-width: " + '594px') {
            margin-left: 0;
            text-align: center;
        }
        &:after {
            position: absolute;
            width: 86px;
            height: 101px;
            top: -11px;
            left: -71px;
            background-image: unset; //background-image: url(/static/images/modal_title_fragment.png);
            background-size: cover;
            background-repeat: no-repeat;
            content: '';
            @include respond-to("max-width: " + '594px') {
                display: none;
            }
        }
    }

    h2 {
        -webkit-font-smoothing: antialiased;
    }

    .modal-enter-site {
        width: 100%;
        background-color: $BLUE;
        font-family: $HEADING_FONT_STACK;
        -webkit-font-smoothing: antialiased;
        font-size: 2em;
    }

    .tingle-modal-box__content {
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: 200px 100%; // background-image: url(/static/images/content-bg.png);
        background-color: #f2f3f5;
        padding: 0;

        .modal-content {
            padding: 3em;
        }
    }

    ul {
        font-family: $HEADING_FONT_STACK;
        font-weight: 300;
        list-style-position: inside;
        padding-left: 0;
        -webkit-font-smoothing: antialiased;
        font-size: 1.5em;

        li {
            margin: 10px 0;
        }
    }
}

@keyframes expand {
    from {
        -webkit-mask-size: 10%;
    }

    to {
        -webkit-mask-size: 1000px;
    }
}

nav.header-nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;

    .logo {
        display: inline-block;
        width: 35%;
        text-align: center;
    }

    .links {
        background-color: $BLUE;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        width: 60%;
        height: 55px;
        float: right;
        transition: all .5s;

        .mobile-nav {
            padding: 18px 2vw;
            height: 55px;
            display: none;

            i {
                color: $WHITE;
            }
        }

        ul {
            padding: 0;
            margin: 0;
            background-color: $BLUE;
            white-space: nowrap;
            display: flex;
            justify-content: space-around;
            transition: transform .5s;

            @include respond-to("max-width: " + $MEDIUM_LARGE_SCREEN_WIDTH) {
                display: block;

                li {
                    display: flex;
                    justify-content: center;
                    padding: 18px 1.5vw;
                }
                transform: translateY(-100%) rotateX(90deg);
                overflow: hidden;

                &.open {
                    transform: translateY(0) rotateX(0deg); // -webkit-mask-image: url('/static/images/logo.png'); -webkit-mask-repeat: no-repeat; -webkit-mask-size: 100px;
                    overflow: visible;
                    animation-name: expand;
                    animation-duration: 1.5s;
                    animation-fill-mode: forwards;
                    mask-position: center;
                }
            }
        }

        li {
            display: inline-block;
            padding: 18px 1.5vw;
            overflow: hidden;
            background-color: $BLUE;
        }

        a {
            color: $WHITE;
            background-color: $BLUE;
            font-family: $HEADING_FONT_STACK;
            text-decoration: none;

            @include respond-to("max-width: " + $MEDIUM_LARGE_SCREEN_WIDTH) {
                padding: 20px 1vw;
                display: block;
            }
        }

        &:before {
            content: ' ';
            width: 50px;
            height: 100%;
            position: absolute;
            left: -50px;
            border-top: 55px solid $BLUE;
            border-left: 50px solid transparent;
        }

        @include respond-to("max-width: " + $MEDIUM_LARGE_SCREEN_WIDTH) {
            width: 40%;
            text-align: center;

            .mobile-nav {
                display: block;
            }

            a {
                height: 0;
            }
        }
    }
}

.link-button {
    background-color: $TAN;
    color: $WHITE;
    padding: 10px 15px;
    text-decoration: none;
    white-space: nowrap;
    font-family: $SANS_SERIF_FONT_STACK;
    font-weight: 600;
    text-transform: uppercase;

    &.red {
        background-color: $RED;
    }
}

body > main {
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-image: url(/static/images/content-bg.png);
    background-color: $LIGHT_GREY;

    #Social {
        display: none;
        background-color: $TAN;
        color: $WHITE;
        font-family: $SANS_SERIF_FONT_STACK;
        font-weight: 600;

        .text {
            padding: 2vw 1vw;
            @include font-size(2);
        }

        .icons {
            padding-bottom: 1vw;

            a {
                color: $WHITE;
            }
        }

        @include respond-to("max-width:
" + $MEDIUM_SCREEN_WIDTH) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icons {
                width: 60%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}

.main-content-wrapper.two-column-layout {
    display: flex;

    .main-column {
        width: 75%;
    }

    @include respond-to("max-width: " + $MEDIUM_SCREEN_WIDTH) {
        flex-direction: column;

        .main-column, .sidebar {
            width: 100%;
        }
    }
}

.section-header {
    @extend %wrapper;
    padding-top: 20vw;
    padding-bottom: 20vw;
    background: #eee;
    color: white;
    text-align: center;
    text-transform: uppercase;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    .title {
        color: white;
        margin-bottom: 0;
        font-size: 8vw;
    }

    @include respond-to("min-width: " + $SMALL_SCREEN_WIDTH) {
        .title {
            font-size: 5vw;
        }
    }
}

body.page {
    .main-content-wrapper {
        .main-column {
            padding: 10vw 3vw;
        }
    }
}

.sub-nav {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    min-height: 300px;
    padding: 20px;
    padding-left: 35px;
    margin-top: -40px;
    margin-bottom: 20px;
    color: $WHITE;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-image: url(/static/images/subnav-bg.png);
    width: 75%;
    background-color: $BLUE;

    .sub-nav-title {
        @include font-size(3);
        font-weight: 600;
        margin-bottom: 20px;
        line-height: 1.2;
        text-transform: uppercase;
        color: $WHITE;
    }

    ul {
        margin: 0;
        padding: 0;
        @include font-size(1.6);
        list-style-type: none;

        li {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        li.selected a, a.current_item {
            color: darken($WHITE, 30%);
        }

        a {
            display: block;
            line-height: 1.25;
            padding: 3px 0;
            text-decoration: none;
            color: $WHITE;
            font-weight: bold;
        }
    }
}

.main-footer {
    @extend %wrapper;
    padding: 20px 0;
    position: relative;
    background-color: $BLUE;
    color: $WHITE;
    background-image: url(/static/images/footer-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    #FooterPromo {
        padding: auto 6%;

        .banner {
            text-align: center;
        }

        img {
            margin: 20px auto;
        }
    }

    #FooterBigNav, #FooterSmallNav {
        display: flex;
        justify-content: space-around;
    }

    #FooterBigNav {
        @include font-size(2);
        text-transform: uppercase;
    }

    #FooterSmallNav {
        a {
            border-right: 2px solid $GREY;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 2px 10px;

            &:last-child {
                border-right: none;
            }
        }
    }

    .flex-wrapper {
        display: flex;
        flex-direction: row;
        padding: 1% 6%;

        .address-wrapper {
            display: flex;
            flex-direction: row;
            width: 25%;
            align-items: center;

            .address {
                @include font-size(1.4);

                b {
                    font-family: $SANS_SERIF_FONT_STACK;
                    font-weight: 600;
                    @include font-size(1.8);
                }
            }
        }

        .link-wrapper {
            width: 70%;
            font-family: $SANS_SERIF_FONT_STACK;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            a {
                text-decoration: none;
                color: $WHITE;
                padding: 4px 10px;
            }
        }

        @include respond-to("max-width: " + $MEDIUM_LARGE_SCREEN_WIDTH) {
            flex-direction: column;

            .address-wrapper, .link-wrapper {
                width: 100%;
            }

            #FooterBigNav {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin: 30px auto;
            }

            #FooterSmallNav {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;

                a {
                    border: none;
                }
            }
        }
    }

    #Social {
        position: absolute;
        height: 65px;
        top: -65px;
        left: 0;
        display: flex;
        flex-direction: row;
        background-color: $BLUE;
        color: $WHITE;
        width: 40%;

        &:after {
            content: ' ';
            position: absolute;
            height: 65px;
            top: 0;
            right: -65px;
            width: 65px;
            border-bottom: 65px solid $BLUE;
            border-right: 30px solid transparent;

            @include respond-to("max-width:
" + $MEDIUM_LARGE_SCREEN_WIDTH) {
                display: none;
            }
        }

        .text {
            width: 75%;
            text-align: center;
            color: $WHITE;
            font-family: $SANS_SERIF_FONT_STACK;
            text-transform: uppercase;
            @include font-size(2);

            .light-blue {
                color: $GREY;
            }
        }

        .icon {
            margin-left: 5%;
        }

        .icons {
            width: 25%;
            display: flex;
            justify-content: space-around;

            a {
                color: $WHITE;
            }
        }

        .text, .icons {
            padding-top: 5%;
            padding-bottom: 5%;
        }

        @include respond-to("max-width:
" + $MEDIUM_LARGE_SCREEN_WIDTH) {
            position: relative;
            width: 100%;
            padding: 20px 1%;

            .text, .icons {
                padding: 0;
            }

            .text {
                width: 60%;
            }

            .icons {
                width: 35%;
            }
        }
    }

    .copyright {
        @include font-size(1.1);
        margin-bottom: 0;
        color: $LIGHT_BLUE;
        font-family: $SERIF_FONT_STACK;
        margin: 4px 10px;
    }
}
@import "partials/form";
@import "partials/pagination";
@import "partials/page_content";
@import "partials/home";
@import "partials/blog";
@import "partials/events";
