@charset "UTF-8";
/* RESET rules from Eric Meyer http://meyerweb.com/eric/tools/css/reset/  */
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Teko");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*** BASIC TYPOGRAPHY RULES BORROWED FROM BLUEPRINT */
/* Default font settings.
The font-size percentage is of 16px. (0.75 * 16px = 12px) */
html {
  font-size: 100.01%; }

body {
  font-size: 100%;
  /* 16px */
  color: #222;
  background: #fff;
  font-family: "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif; }

/* Headings
-------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  color: #111; }

h1 {
  font-size: 2.5em;
  line-height: 1;
  margin-bottom: 0.5em; }

h2 {
  font-size: 1.9em;
  margin-bottom: 0.75em; }

h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em; }

h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em; }

h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em; }

h6 {
  font-size: 1em;
  font-weight: bold; }

h1 img, h2 img, h3 img,
h4 img, h5 img, h6 img {
  margin: 0; }

/* Text elements
-------------------------------------------------------------- */
p {
  margin: 0 0 1.5em;
  line-height: 1.5; }

a:focus,
a:hover {
  color: #09f; }

a {
  color: #06c;
  text-decoration: underline; }

blockquote {
  margin: 1.5em;
  color: #666;
  font-style: italic; }

strong, dfn {
  font-weight: bold; }

em, dfn {
  font-style: italic; }

sup, sub {
  line-height: 0; }

abbr,
acronym {
  border-bottom: 1px dotted #666; }

address {
  margin: 0 0 1.5em;
  font-style: italic; }

del {
  color: #666; }

pre {
  margin: 1.5em 0;
  white-space: pre; }

pre, code, tt {
  font: 1em 'andale mono', 'lucida console', monospace;
  line-height: 1.5; }

/* Lists
-------------------------------------------------------------- */
li ul,
li ol {
  margin: 0; }

ul, ol {
  margin: 0 1.5em 1.5em 0;
  padding-left: 1.5em; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

dl {
  margin: 0 0 1.5em 0; }

dl dt {
  font-weight: bold; }

dd {
  margin-left: 1.5em; }

/* Tables
-------------------------------------------------------------- */
/*
Because of the need for padding on TH and TD, the vertical rhythm
on table cells has to be 27px, instead of the standard 18px or 36px
of other elements.
*/
table {
  margin-bottom: 1.4em;
  width: 100%;
  border: 1px solid #C9C68F; }

th {
  font-weight: bold; }

thead th {
  background: #E9E6AF;
  border-bottom: 1px solid #C9C68F;
  font-family: "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif !important; }

th, td, caption {
  padding: 10px 15px 10px 10px; }

/*
You can zebra-stripe your tables in outdated browsers by adding
the class "even" to every other table row.
*/
tbody tr:nth-child(even) td,
tbody tr.even td {
  background: #E9E6AF; }

tfoot {
  font-style: italic; }

caption {
  background: #758053;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  -webkit-text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px;
  -moz-text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px;
  text-shadow: rgba(0, 0, 0, 0.5) -1px 0, rgba(0, 0, 0, 0.3) 0 -1px, rgba(255, 255, 255, 0.5) 0 1px, rgba(0, 0, 0, 0.3) -1px -2px; }

/* Misc classes
-------------------------------------------------------------- */
.small {
  font-size: .8em;
  margin-bottom: 1.875em;
  line-height: 1.875em; }

.large {
  font-size: 1.2em;
  line-height: 2.5em;
  margin-bottom: 1.25em; }

.hide {
  display: none; }

.quiet {
  color: #666; }

.loud {
  color: #000; }

.highlight {
  background: #ff0; }

.added {
  background: #060;
  color: #fff; }

.removed {
  background: #900;
  color: #fff; }

.first {
  margin-left: 0;
  padding-left: 0; }

.last {
  margin-right: 0;
  padding-right: 0; }

.top {
  margin-top: 0;
  padding-top: 0; }

.bottom {
  margin-bottom: 0;
  padding-bottom: 0; }

/* MISC STYLES, clears, etc. */
/* Clearing floats without extra markup
Based on How To Clear Floats Without Structural Markup by PiE
[http://www.positioniseverything.net/easyclearing.html] */
.clearfix:after, .container:after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  overflow: hidden; }

.clearfix, .container {
  display: block; }

/* Regular clearing
apply to column that should drop below previous ones. */
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0; }

.post-list .post:after {
  content: "";
  display: table;
  clear: both; }

.post-list .post .media-img {
  display: block;
  float: left;
  margin-right: 5%;
  width: 30%; }
  .post-list .post .media-img > img {
    display: block; }

.post-list .post .media-body:after {
  content: "";
  display: table;
  clear: both; }

.post-list .post {
  padding-bottom: 40px;
  margin-bottom: 40px; }
  .post-list .post header {
    margin-bottom: 20px; }
    .post-list .post header .title {
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;
      font-size: 27px;
      font-size: 2.7rem;
      margin-bottom: 10px; }
    .post-list .post header time {
      font-size: 13px;
      font-size: 1.3rem;
      margin-bottom: 0;
      display: block;
      margin-bottom: 5px;
      text-transform: uppercase; }
  .post-list .post .excerpt {
    margin-bottom: 20px;
    color: #063669; }
  .post-list .post .more {
    max-width: 150px;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold; }
  .post-list .post:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 770px) {
    .post-list .post {
      padding-bottom: 20px;
      margin-bottom: 20px; }
      .post-list .post header .title {
        font-size: 18px;
        font-size: 1.8rem; } }

.post header {
  padding-bottom: 10px;
  margin-bottom: 10px; }
  .post header > .title {
    font-size: 25px;
    font-size: 2.5rem;
    font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
    font-weight: 600;
    -webkit-font-smoothing: antialiased; }
  .post header > time {
    font-size: 16px;
    font-size: 1.6rem;
    display: block;
    text-transform: uppercase; }

.section-header, .main-footer {
  padding: 0 6%;
  position: relative; }
  .section-header:after, .main-footer:after {
    content: "";
    display: table;
    clear: both; }

.two-column-layout .sidebar {
  position: relative;
  float: left;
  width: 25%;
  margin-left: 5%;
  min-height: 1px;
  background-size: contain;
  background-image: url(/static/images/sidebar-bg-tile.png); }
  @media screen and (max-width: 770px) {
    .two-column-layout .sidebar {
      width: auto;
      float: none;
      margin-left: 0;
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid #F2F3F5; } }

.two-column-layout .main-column {
  width: 70%;
  min-height: 1px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: url(/static/images/content-bg.png); }
  @media screen and (max-width: 770px) {
    .two-column-layout .main-column {
      width: auto;
      float: none; } }

.two-column-layout:after {
  content: "";
  display: table;
  clear: both; }

.one-column-narrow-layout .main-column {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  /* 10/16 make - this make em/rem math a power of 10 */ }

body {
  font-size: 16px;
  font-size: 1.6rem;
  min-width: 240px;
  font-family: "Open Sans", "Times New Roman", serif;
  color: #031D39; }

header, footer, nav, aside, article, section {
  display: block; }

img, object, video, media, embed {
  max-width: 100%;
  height: auto; }

iframe {
  max-width: 100%; }

a {
  color: #012853; }
  a:hover {
    color: black; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased; }

h1 a, h2 a, h3 a, h4 a, h5 a {
  text-decoration: none; }

h1 {
  color: #031D39; }

h2, h3, h4, h5, h6 {
  color: #012853; }

.trans, section#Banner {
  -webkit-transition: all .33s;
  -moz-transition: all .33s;
  -ms-transition: all .33s;
  -o-transition: all .33s;
  transition: all .33s; }

.chromeframe {
  padding: 20px 0;
  text-align: center;
  background-color: #b3aa19;
  color: #222;
  font-size: 1.2em;
  position: relative;
  z-index: 1000; }
  .chromeframe p {
    max-width: 700px;
    margin: 0 auto; }

.hidden {
  display: none; }

@media screen and (max-width: 594px) {
  .tingle-modal-box__content {
    overflow-x: hidden !important;
    overflow-y: hidden !important; } }

.modal-dialog .bg-container {
  width: 100%;
  text-align: right; }

.modal-dialog .tingle-modal-box {
  width: 54%; }
  @media screen and (max-width: 594px) {
    .modal-dialog .tingle-modal-box {
      width: 100%; } }

.modal-dialog li {
  color: #777; }

.modal-dialog a {
  text-decoration: underline; }

.modal-dialog h1 {
  text-transform: uppercase;
  color: #ecf0f1;
  display: block;
  position: relative;
  background-color: #9F2630;
  text-align: center;
  font-size: 3em;
  -webkit-font-smoothing: antialiased;
  height: 92px;
  padding: 2rem;
  border-bottom: 8px solid #777; }
  @media screen and (max-width: 1200px) {
    .modal-dialog h1 {
      font-size: 2.5em; } }
  @media screen and (max-width: 960px) {
    .modal-dialog h1 {
      font-size: 2em; } }
  @media screen and (max-width: 594px) {
    .modal-dialog h1 {
      margin-left: 0;
      text-align: center; } }
  .modal-dialog h1:after {
    position: absolute;
    width: 86px;
    height: 101px;
    top: -11px;
    left: -71px;
    background-image: unset;
    background-size: cover;
    background-repeat: no-repeat;
    content: ''; }
    @media screen and (max-width: 594px) {
      .modal-dialog h1:after {
        display: none; } }

.modal-dialog h2 {
  -webkit-font-smoothing: antialiased; }

.modal-dialog .modal-enter-site {
  width: 100%;
  background-color: #012853;
  font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 2em; }

.modal-dialog .tingle-modal-box__content {
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: 200px 100%;
  background-color: #f2f3f5;
  padding: 0; }
  .modal-dialog .tingle-modal-box__content .modal-content {
    padding: 3em; }

.modal-dialog ul {
  font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
  font-weight: 300;
  list-style-position: inside;
  padding-left: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5em; }
  .modal-dialog ul li {
    margin: 10px 0; }

@keyframes expand {
  from {
    -webkit-mask-size: 10%; }
  to {
    -webkit-mask-size: 1000px; } }

nav.header-nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%; }
  nav.header-nav .logo {
    display: inline-block;
    width: 35%;
    text-align: center; }
  nav.header-nav .links {
    background-color: #012853;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    width: 60%;
    height: 55px;
    float: right;
    transition: all .5s; }
    nav.header-nav .links .mobile-nav {
      padding: 18px 2vw;
      height: 55px;
      display: none; }
      nav.header-nav .links .mobile-nav i {
        color: #ecf0f1; }
    nav.header-nav .links ul {
      padding: 0;
      margin: 0;
      background-color: #012853;
      white-space: nowrap;
      display: flex;
      justify-content: space-around;
      transition: transform .5s; }
      @media screen and (max-width: 960px) {
        nav.header-nav .links ul {
          display: block;
          transform: translateY(-100%) rotateX(90deg);
          overflow: hidden; }
          nav.header-nav .links ul li {
            display: flex;
            justify-content: center;
            padding: 18px 1.5vw; }
          nav.header-nav .links ul.open {
            transform: translateY(0) rotateX(0deg);
            overflow: visible;
            animation-name: expand;
            animation-duration: 1.5s;
            animation-fill-mode: forwards;
            mask-position: center; } }
    nav.header-nav .links li {
      display: inline-block;
      padding: 18px 1.5vw;
      overflow: hidden;
      background-color: #012853; }
    nav.header-nav .links a {
      color: #ecf0f1;
      background-color: #012853;
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      text-decoration: none; }
      @media screen and (max-width: 960px) {
        nav.header-nav .links a {
          padding: 20px 1vw;
          display: block; } }
    nav.header-nav .links:before {
      content: ' ';
      width: 50px;
      height: 100%;
      position: absolute;
      left: -50px;
      border-top: 55px solid #012853;
      border-left: 50px solid transparent; }
    @media screen and (max-width: 960px) {
      nav.header-nav .links {
        width: 40%;
        text-align: center; }
        nav.header-nav .links .mobile-nav {
          display: block; }
        nav.header-nav .links a {
          height: 0; } }

.link-button {
  background-color: #B59F67;
  color: #ecf0f1;
  padding: 10px 15px;
  text-decoration: none;
  white-space: nowrap;
  font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase; }
  .link-button.red {
    background-color: #9F2630; }

body > main {
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-image: url(/static/images/content-bg.png);
  background-color: #F2F3F5; }
  body > main #Social {
    display: none;
    background-color: #B59F67;
    color: #ecf0f1;
    font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
    font-weight: 600; }
    body > main #Social .text {
      padding: 2vw 1vw;
      font-size: 20px;
      font-size: 2rem; }
    body > main #Social .icons {
      padding-bottom: 1vw; }
      body > main #Social .icons a {
        color: #ecf0f1; }
    @media screen and (max-width: 860px) {
      body > main #Social {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        body > main #Social .icons {
          width: 60%;
          display: flex;
          justify-content: space-between; } }

.main-content-wrapper.two-column-layout {
  display: flex; }
  .main-content-wrapper.two-column-layout .main-column {
    width: 75%; }
  @media screen and (max-width: 860px) {
    .main-content-wrapper.two-column-layout {
      flex-direction: column; }
      .main-content-wrapper.two-column-layout .main-column, .main-content-wrapper.two-column-layout .sidebar {
        width: 100%; } }

.section-header {
  padding-top: 20vw;
  padding-bottom: 20vw;
  background: #eee;
  color: white;
  text-align: center;
  text-transform: uppercase;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%; }
  .section-header .title {
    color: white;
    margin-bottom: 0;
    font-size: 8vw; }
  @media screen and (min-width: 770px) {
    .section-header .title {
      font-size: 5vw; } }

body.page .main-content-wrapper .main-column {
  padding: 10vw 3vw; }

.sub-nav {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  min-height: 300px;
  padding: 20px;
  padding-left: 35px;
  margin-top: -40px;
  margin-bottom: 20px;
  color: #ecf0f1;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-image: url(/static/images/subnav-bg.png);
  width: 75%;
  background-color: #012853; }
  .sub-nav .sub-nav-title {
    font-size: 30px;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #ecf0f1; }
  .sub-nav ul {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-size: 1.6rem;
    list-style-type: none; }
    .sub-nav ul li {
      margin-bottom: 10px; }
      .sub-nav ul li:last-child {
        margin-bottom: 0; }
    .sub-nav ul li.selected a, .sub-nav ul a.current_item {
      color: #94aab0; }
    .sub-nav ul a {
      display: block;
      line-height: 1.25;
      padding: 3px 0;
      text-decoration: none;
      color: #ecf0f1;
      font-weight: bold; }

.main-footer {
  padding: 20px 0;
  position: relative;
  background-color: #012853;
  color: #ecf0f1;
  background-image: url(/static/images/footer-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%; }
  .main-footer #FooterPromo {
    padding: auto 6%; }
    .main-footer #FooterPromo .banner {
      text-align: center; }
    .main-footer #FooterPromo img {
      margin: 20px auto; }
  .main-footer #FooterBigNav, .main-footer #FooterSmallNav {
    display: flex;
    justify-content: space-around; }
  .main-footer #FooterBigNav {
    font-size: 20px;
    font-size: 2rem;
    text-transform: uppercase; }
  .main-footer #FooterSmallNav a {
    border-right: 2px solid #A6B0BB;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 2px 10px; }
    .main-footer #FooterSmallNav a:last-child {
      border-right: none; }
  .main-footer .flex-wrapper {
    display: flex;
    flex-direction: row;
    padding: 1% 6%; }
    .main-footer .flex-wrapper .address-wrapper {
      display: flex;
      flex-direction: row;
      width: 25%;
      align-items: center; }
      .main-footer .flex-wrapper .address-wrapper .address {
        font-size: 14px;
        font-size: 1.4rem; }
        .main-footer .flex-wrapper .address-wrapper .address b {
          font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
          font-weight: 600;
          font-size: 18px;
          font-size: 1.8rem; }
    .main-footer .flex-wrapper .link-wrapper {
      width: 70%;
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end; }
      .main-footer .flex-wrapper .link-wrapper a {
        text-decoration: none;
        color: #ecf0f1;
        padding: 4px 10px; }
    @media screen and (max-width: 960px) {
      .main-footer .flex-wrapper {
        flex-direction: column; }
        .main-footer .flex-wrapper .address-wrapper, .main-footer .flex-wrapper .link-wrapper {
          width: 100%; }
        .main-footer .flex-wrapper #FooterBigNav {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          margin: 30px auto; }
        .main-footer .flex-wrapper #FooterSmallNav {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%; }
          .main-footer .flex-wrapper #FooterSmallNav a {
            border: none; } }
  .main-footer #Social {
    position: absolute;
    height: 65px;
    top: -65px;
    left: 0;
    display: flex;
    flex-direction: row;
    background-color: #012853;
    color: #ecf0f1;
    width: 40%; }
    .main-footer #Social:after {
      content: ' ';
      position: absolute;
      height: 65px;
      top: 0;
      right: -65px;
      width: 65px;
      border-bottom: 65px solid #012853;
      border-right: 30px solid transparent; }
      @media screen and (max-width: 960px) {
        .main-footer #Social:after {
          display: none; } }
    .main-footer #Social .text {
      width: 75%;
      text-align: center;
      color: #ecf0f1;
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      font-size: 20px;
      font-size: 2rem; }
      .main-footer #Social .text .light-blue {
        color: #A6B0BB; }
    .main-footer #Social .icon {
      margin-left: 5%; }
    .main-footer #Social .icons {
      width: 25%;
      display: flex;
      justify-content: space-around; }
      .main-footer #Social .icons a {
        color: #ecf0f1; }
    .main-footer #Social .text, .main-footer #Social .icons {
      padding-top: 5%;
      padding-bottom: 5%; }
    @media screen and (max-width: 960px) {
      .main-footer #Social {
        position: relative;
        width: 100%;
        padding: 20px 1%; }
        .main-footer #Social .text, .main-footer #Social .icons {
          padding: 0; }
        .main-footer #Social .text {
          width: 60%; }
        .main-footer #Social .icons {
          width: 35%; } }
  .main-footer .copyright {
    font-size: 11px;
    font-size: 1.1rem;
    margin-bottom: 0;
    color: #0E4888;
    font-family: "Open Sans", "Times New Roman", serif;
    margin: 4px 10px; }

label {
  color: black;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercaes;
  font-weight: bold; }

input[type=text], input[type=email], input[type=number],
input[type=password], textarea {
  border: 1px solid #777;
  background-color: white;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 5px 5px;
  color: #031D39;
  border-radius: 4px; }
  input[type=text]:focus, input[type=email]:focus, input[type=number]:focus,
  input[type=password]:focus, textarea:focus {
    background-color: #ffff99;
    outline: none; }

select {
  border: 1px solid #777;
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%; }
  select:focus {
    outline: none; }

button, input[type=submit], .button {
  background-color: #9F2630;
  display: inline-block;
  border: 0;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: normal;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  color: #ecf0f1;
  font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  border-radius: 4px;
  transition: background-color ease-in 0.2s; }
  button i, input[type=submit] i, .button i {
    margin-left: 10px; }
  button:hover, input[type=submit]:hover, .button:hover {
    background-color: #761c24;
    color: #ecf0f1; }
  button:focus, input[type=submit]:focus, .button:focus {
    outline: none; }

form .form-group .help-block {
  display: block;
  margin-top: 5px; }

form .form-group:has-error {
  border: 2px solid #DF2133;
  padding: 7px 5px; }
  form .form-group:has-error .help-block {
    color: #DF2133; }

.pagination {
  margin: 40px 0 40px 0;
  text-align: center; }
  .pagination:after {
    content: "";
    display: table;
    clear: both; }
  .pagination a, .pagination .next, .pagination .prev {
    margin-right: 10px;
    background-color: #9F2630;
    display: inline-block;
    border: 0;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: normal;
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    color: #ecf0f1;
    font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
    transition: background-color ease-in 0.2s;
    max-width: 130px; }
    .pagination a i, .pagination .next i, .pagination .prev i {
      margin-left: 10px; }
    .pagination a:hover, .pagination .next:hover, .pagination .prev:hover {
      background-color: #761c24;
      color: #ecf0f1; }
  .pagination .next {
    float: right; }
    .pagination .next i {
      margin-left: 10px; }
  .pagination .prev {
    float: left; }
    .pagination .prev i {
      margin-right: 10px; }
  .pagination .disabled {
    opacity: 0; }
  .pagination .current {
    font-weight: normal;
    font-size: 14px;
    font-size: 1.4rem;
    text-transform: uppercase;
    display: none; }

/*** Content div for Post & Page text content */
.sidebar .callout {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ecf0f1;
  background-size: cover;
  background-repeat: no-repeat;
  width: 75%;
  text-align: center; }
  .sidebar .callout h2, .sidebar .callout h3, .sidebar .callout a {
    color: #ecf0f1;
    margin: 0; }
  .sidebar .callout a {
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: block;
    padding: 80px 5px; }

.sub-page-title, .textblock .title {
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 30px; }
  @media screen and (max-width: 450px) {
    .sub-page-title, .textblock .title {
      font-size: 20px;
      font-size: 2rem;
      margin-bottom: 20px; } }

.textblock {
  margin-bottom: 30px; }

.content {
  font-size: 16px;
  font-size: 1.6rem; }
  .content:after {
    content: "";
    display: table;
    clear: both; }
  .content a {
    font-weight: 700;
    text-decoration: none; }
  .content li {
    line-height: 1.25;
    margin-bottom: .5em; }
    .content li:last-child {
      margin-bottom: 0; }
  .content ul li {
    list-style-type: none;
    position: relative; }
    .content ul li:before {
      position: absolute;
      top: 0;
      left: -1.5em;
      content: '●';
      font-size: 75%;
      padding-top: 3px;
      color: #777; }
  .content h2 {
    font-size: 36px;
    font-size: 3.6rem;
    font-weight: normal; }
  .content h3 {
    font-size: 27px;
    font-size: 2.7rem;
    font-weight: 600; }
  .content h4 {
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: 700; }
  .content strong {
    font-weight: 700; }
  .content blockquote {
    font-style: normal;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5; }
  .content dl {
    margin-bottom: 20px; }
    .content dl dt {
      font-size: 16px;
      font-size: 1.6rem;
      margin-bottom: 5px; }
    .content dl dd {
      margin: 0;
      padding: 0;
      line-height: 1.5;
      margin-bottom: 25px; }
  .content figure, .content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 1.5em; }
    .content figure.alignleft, .content figure.left, .content img.alignleft, .content img.left {
      float: left;
      max-width: 50%;
      margin-right: 1.5em; }
    .content figure.alignright, .content figure.right, .content img.alignright, .content img.right {
      float: right;
      max-width: 50%;
      margin-left: 1.5em; }
    .content figure.center, .content img.center {
      margin-left: auto;
      margin-right: auto;
      max-width: 75%; }
  .content .button {
    max-width: 300px; }
  .content .image-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; }
    .content .image-grid img {
      margin-right: 10px; }
  @media screen and (max-width: 770px) {
    .content figure.alignleft, .content figure.alignright, .content figure.left, .content figure.right, .content figure.center, .content img.alignleft, .content img.alignright, .content img.left, .content img.right, .content img.center {
      float: none;
      width: auto;
      max-width: 100%;
      padding-right: 0;
      margin-left: 0; }
    .content .image-grid {
      flex-direction: column; } }
  @media screen and (max-width: 450px) {
    .content h2 {
      font-size: 25px;
      font-size: 2.5rem; }
    .content h3 {
      font-size: 22.5px;
      font-size: 2.25rem; }
    .content h4 {
      font-size: 20px;
      font-size: 2rem; } }

section#Banner {
  background-color: #012853;
  padding: 2.5% 0;
  margin: 20px 0;
  text-align: center;
  align-items: center; }
  section#Banner h2 {
    font-size: 80px;
    font-size: 8rem;
    color: #B59F67;
    padding: 0 2.5%; }
    @media screen and (max-width: 860px) {
      section#Banner h2 {
        margin-bottom: unset;
        padding: 20px;
        font-size: 50px;
        font-size: 5rem; } }
  section#Banner div.wrap {
    display: flex;
    flex-direction: column; }
    section#Banner div.wrap div {
      display: flex;
      align-items: center; }
      section#Banner div.wrap div.content {
        width: 66%;
        flex-direction: column;
        background-color: #9F2630;
        padding: 5%; }
        section#Banner div.wrap div.content * {
          color: #ecf0f1; }
        section#Banner div.wrap div.content h3, section#Banner div.wrap div.content h4 {
          text-transform: uppercase; }
        section#Banner div.wrap div.content h3 {
          margin-bottom: unset;
          margin: 10px; }
          section#Banner div.wrap div.content h3 span {
            color: #B59F67; }
        section#Banner div.wrap div.content h4 {
          font-size: 40px;
          font-size: 4rem;
          margin: unset;
          line-height: .8; }
        section#Banner div.wrap div.content a:not(.link-button) {
          text-decoration: underline; }
        section#Banner div.wrap div.content a.link-button {
          font-size: 30px;
          font-size: 3rem; }
        @media screen and (max-width: 860px) {
          section#Banner div.wrap div.content {
            width: 100%; } }
        @media screen and (min-width: 860px) {
          section#Banner div.wrap div.content {
            padding: 2.5% 10% 2.5% 5%;
            clip-path: polygon(0 0, 100% 0, 92.5% 100%, 0% 100%); }
            section#Banner div.wrap div.content h3 {
              font-size: 45px;
              font-size: 4.5rem; }
            section#Banner div.wrap div.content h4 {
              font-size: 50px;
              font-size: 5rem; }
            section#Banner div.wrap div.content a.link-button {
              display: none; } }
      section#Banner div.wrap div.other {
        justify-content: left;
        flex-direction: column;
        padding: 40px; }
        section#Banner div.wrap div.other div {
          width: auto; }
          section#Banner div.wrap div.other div * {
            color: white; }
          section#Banner div.wrap div.other div a.link-button {
            font-size: 40px;
            font-size: 4rem; }
          section#Banner div.wrap div.other div img {
            max-width: 125px; }
        @media screen and (min-width: 860px) {
          section#Banner div.wrap div.other {
            display: none;
            width: 33%;
            display: flex;
            justify-content: center;
            padding: 2vw 3vw; }
            section#Banner div.wrap div.other div a.link-button {
              font-size: 70px;
              font-size: 7rem;
              padding: 30px 60px; } }
    @media screen and (min-width: 860px) {
      section#Banner div.wrap {
        flex-direction: row; } }
  section#Banner a {
    width: 100%; }

section#EventsUpcoming {
  display: flex;
  flex-direction: row; }
  section#EventsUpcoming time {
    color: #B59F67;
    font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase; }
  section#EventsUpcoming .event {
    width: 40%;
    padding: 4%; }
  section#EventsUpcoming .all-event-link {
    width: 20%;
    padding: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/static/images/mp-events-bg-btn.png);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat; }
    section#EventsUpcoming .all-event-link a {
      display: block;
      text-decoration: none;
      color: #ecf0f1;
      font-size: 2em;
      font-weight: 600;
      font-family: 'Teko';
      padding: 20px; }
  section#EventsUpcoming .event {
    background-color: #012853; }
    section#EventsUpcoming .event time {
      color: #B59F67; }
    section#EventsUpcoming .event .event-name, section#EventsUpcoming .event .event-description {
      color: #ecf0f1; }
  @media screen and (max-width: 860px) {
    section#EventsUpcoming {
      flex-direction: column; }
      section#EventsUpcoming .event, section#EventsUpcoming .all-event-link {
        width: 100%;
        padding-bottom: 10%; } }

section#Callouts {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  section#Callouts .callout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 30%;
    min-height: 500px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 2vw 3vw;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ecf0f1; }
    section#Callouts .callout img {
      margin-bottom: 20px; }
    section#Callouts .callout h2 {
      color: #ecf0f1;
      font-size: 35px; }
    section#Callouts .callout#PlanPrivateEvent {
      background-image: url(/static/images/mp-callout_plan.jpg); }
    section#Callouts .callout#Tailgating {
      background-image: url(/static/images/mp-callout_tailgating.jpg); }
    section#Callouts .callout#Sponsor {
      background-image: url(/static/images/mp-callout_sponsor.jpg); }
  @media screen and (max-width: 860px) {
    section#Callouts {
      flex-direction: column;
      text-align: center;
      align-items: center; }
      section#Callouts .callout {
        width: 94%;
        align-items: center;
        justify-content: center;
        min-height: 0;
        padding: 6vw;
        margin-top: 20px;
        margin-bottom: 0; }
        section#Callouts .callout .p {
          text-align: center; }
      section#Callouts .callout:last-child {
        margin-bottom: 20px; } }

section#HomePagePromos {
  display: flex;
  flex-direction: row; }
  section#HomePagePromos .promo {
    width: calc(100% / 3);
    height: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding: 4% 1%; }

section#AboutTheStadium {
  background-image: url(/static/images/mp-about-bg.jpg);
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  padding: 10vw 10vw 0 10vw; }
  section#AboutTheStadium .text-wrapper {
    width: 45%;
    color: #ecf0f1;
    margin-bottom: 30px; }
    section#AboutTheStadium .text-wrapper p {
      font-size: 1em;
      font-weight: 300; }
    @media screen and (max-width: 860px) {
      section#AboutTheStadium .text-wrapper {
        width: 100%; } }
  section#AboutTheStadium h2 {
    color: #ecf0f1;
    font-size: 4em; }
  section#AboutTheStadium .container .row {
    height: 25vw;
    display: flex; }
    @media screen and (max-width: 860px) {
      section#AboutTheStadium .container .row {
        flex-direction: column;
        margin: 20px auto;
        height: 20vh; } }
  section#AboutTheStadium .container .row:nth-child(1) .image {
    align-self: flex-end; }
  section#AboutTheStadium .container .image {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin: 0;
    display: inline-block; }
    section#AboutTheStadium .container .image a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: #ecf0f1;
      font-size: 3em;
      font-family: 'Teko';
      font-weight: 600; }
    section#AboutTheStadium .container .image.large {
      width: calc(100%/3 * 2);
      height: 100%; }
    section#AboutTheStadium .container .image.small {
      width: calc(100% / 3);
      height: 50%; }
    section#AboutTheStadium .container .image#Facts {
      background-image: url(/static/images/mp-about-callout-lg_facts.jpg); }
    section#AboutTheStadium .container .image#History {
      background-image: url(/static/images/mp-about-callout-sm_history.jpg); }
    section#AboutTheStadium .container .image#Commission {
      background-image: url(/static/images/mp-about-callout-sm_commission.jpg); }
    section#AboutTheStadium .container .image#Diagram {
      background-image: url(/static/images/mp-about-callout-lg_diagram.jpg); }
    @media screen and (max-width: 860px) {
      section#AboutTheStadium .container .image.large, section#AboutTheStadium .container .image.small {
        width: 100% !important;
        height: 50% !important;
        display: block;
        font-size: 0.9em;
        margin: 5px auto; } }

section#NewsAndAnnouncements {
  display: flex;
  padding: 5vw;
  align-items: center; }
  section#NewsAndAnnouncements .wrapper {
    width: 50%;
    padding: 5vw; }
  section#NewsAndAnnouncements h2 {
    font-size: 60px;
    font-size: 6rem;
    margin-bottom: 10px; }
  section#NewsAndAnnouncements .news-item {
    background-color: white;
    margin: 20px;
    padding: 3vw;
    border-right: 10px solid #B59F67;
    box-shadow: 5px 5px 20px rgba(10, 10, 10, 0.2); }
    section#NewsAndAnnouncements .news-item time {
      color: #B59F67;
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      font-size: 20px;
      font-size: 2rem;
      font-weight: 600;
      text-transform: uppercase; }
    section#NewsAndAnnouncements .news-item a {
      text-decoration: none; }
      section#NewsAndAnnouncements .news-item a h2 {
        font-size: 30px;
        font-size: 3rem; }
    section#NewsAndAnnouncements .news-item a.red {
      color: #9F2630;
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      font-weight: 600;
      text-transform: uppercase; }
  @media screen and (max-width: 860px) {
    section#NewsAndAnnouncements {
      flex-direction: column;
      padding-bottom: 50px; }
      section#NewsAndAnnouncements .wrapper {
        width: 100%;
        padding: 0;
        margin: 20px auto; }
      section#NewsAndAnnouncements .wrapper:nth-child(1) {
        padding: 3vw; }
      section#NewsAndAnnouncements h2 {
        font-size: 30px;
        font-size: 3rem; } }

.blog .content-header {
  margin-bottom: 40px; }
  .blog .content-header .title {
    font-weight: bold; }
  .blog .content-header .section-title {
    margin-bottom: 0;
    color: #8e44ad;
    font-size: 16px;
    font-size: 1.6rem; }

.post.featured {
  flex-direction: column; }

.post-list .post {
  display: flex; }
  .post-list .post .media-img {
    width: 30%; }
  .post-list .post .media-body {
    width: 70%; }
  @media screen and (max-width: 960px) {
    .post-list .post {
      flex-direction: column; }
      .post-list .post .media-img, .post-list .post .media-body {
        width: 100%; } }

.post-list .post.featured .media-img {
  float: none;
  margin-bottom: 30px;
  width: 100%;
  margin-right: 0; }

.post-list .post.featured .media-body {
  float: none; }

.post-list .post.featured header .title {
  font-size: 30px;
  font-size: 3rem; }
  .post-list .post.featured header .title a {
    font-size: 30px;
    font-size: 3rem; }

.post-list .post.featured header time, .post-list .post.featured header time > span {
  font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #012853; }

.post-list .post.featured .excerpt {
  font-size: 20px;
  font-size: 2rem; }

@media screen and (max-width: 770px) {
  .post-list .post.featured .media-img {
    display: block; }
  .post-list .post.featured header .title {
    font-size: 20px;
    font-size: 2rem; }
  .post-list .post.featured header time {
    font-size: 16px;
    font-size: 1.6rem; }
  .post-list .post.featured .excerpt {
    font-size: 16px;
    font-size: 1.6rem; } }

.post time, .post time > span {
  font-family: "Teko", Verdana, Arial, Helvetica, sans-serif !important;
  font-weight: 300;
  text-transform: uppercase;
  color: #012853; }

.post-list.post-feed .post header .title {
  font-size: 30px;
  font-size: 3rem; }
  .post-list.post-feed .post header .title a {
    font-size: 30px;
    font-size: 3rem; }

.event-list {
  display: flex;
  justify-content: space-between; }

.calendar-wrapper {
  margin-top: -40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .calendar-wrapper #Calendar {
    max-width: 500px;
    width: 85%; }
    .calendar-wrapper #Calendar .ui-datepicker-inline {
      background-color: #ecf0f1;
      padding: 20px 5px; }
    .calendar-wrapper #Calendar .ui-datepicker-header {
      background-color: #ecf0f1;
      color: #B59F67;
      font-size: 30px;
      font-size: 3rem;
      text-align: center;
      border: none;
      padding: 20px 10px;
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      font-weight: 700; }
      .calendar-wrapper #Calendar .ui-datepicker-header a {
        text-decoration: none;
        font-family: "Open Sans", "Times New Roman", serif; }
    .calendar-wrapper #Calendar table {
      border: none; }
    .calendar-wrapper #Calendar td {
      padding: 5px 2px;
      text-align: center;
      background-color: #ecf0f1; }
      .calendar-wrapper #Calendar td a {
        text-decoration: none;
        font-family: "Open Sans", "Times New Roman", serif;
        font-size: 15px;
        font-size: 1.5rem; }
    .calendar-wrapper #Calendar thead th {
      background: #ecf0f1;
      border-bottom: none;
      margin-top: 0;
      font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
      font-weight: 700;
      padding: 0;
      padding-bottom: 10px; }
      .calendar-wrapper #Calendar thead th span {
        font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
        font-weight: 700; }
    .calendar-wrapper #Calendar .ui-datepicker-next, .calendar-wrapper #Calendar .ui-datepicker-prev {
      color: #B59F67; }
      .calendar-wrapper #Calendar .ui-datepicker-next.ui-state-disabled, .calendar-wrapper #Calendar .ui-datepicker-prev.ui-state-disabled {
        color: #A6B0BB; }
    .calendar-wrapper #Calendar .ui-datepicker-prev {
      float: left; }
    .calendar-wrapper #Calendar .ui-datepicker-next {
      float: right; }
    .calendar-wrapper #Calendar .event-day a {
      color: #9F2630;
      font-weight: 700;
      font-size: 20px;
      font-size: 2rem; }

.event-date-list-wrapper {
  margin: 20px auto; }

.event {
  width: 48%; }
  .event .title {
    font-size: 30px;
    font-size: 3rem; }
    .event .title a {
      font-size: 30px;
      font-size: 3rem; }
  .event .more {
    color: #9F2630;
    font-weight: 700; }
  .event.featured {
    width: 100%; }
  .event time, .event time > span {
    font-family: "Teko", Verdana, Arial, Helvetica, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    color: #012853; }
  .event .event-thumbnail {
    width: 100%; }
  @media screen and (max-width: 960px) {
    .event {
      width: 100%; } }
